import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { BlockchainContext } from "../../BlockchainContext";
import { Contract, utils } from "ethers";
import btvAssetAbi from "../../../contracts/abi/BTVAsset.json";

export default function useTokenReader(address, wallet) {
  const { account, readAccount, readOnly, provider } =
    useContext(BlockchainContext);
  const [instance] = useState(
    new Contract(address, new utils.Interface(btvAssetAbi.abi), provider)
  );
  const [balance, setBalance] = useState(undefined);
  const [totalSupply, setTotalSupply] = useState(undefined);
  const [available, setAvailable] = useState(undefined);
  const [locked, setLocked] = useState(undefined);
  const checkAccount = wallet ?? account;
  const [reloader, setReloader] = useState(0);

  useEffect(() => {
    async function fetchAvailable() {
      try {
        const res = await instance.balanceOf(
          "0x01f9f1C2F51B82438Be76BcFfFcD1951EA3dEAb9"
        );

        if (res?._isBigNumber) {
          setAvailable(res.toNumber());
        }
      } catch (error) {
        console.error("BalanceOf: Reverted", error);
      }
    }

    async function fetchTotalSupply() {
      try {
        const res = await instance.totalSupply();

        if (res?._isBigNumber) {
          setTotalSupply(res.toNumber());
        }
      } catch (error) {
        console.error("TotalSupply: Reverted", error);
      }
    }

    async function fetchLocked() {
      try {
        const res = await instance.isLocked();

        setLocked(res);
      } catch (error) {
        console.warn("IsLocked: Reverted", error);
      }
    }

    instance.isLocked !== undefined && fetchLocked();
    fetchTotalSupply();
    fetchAvailable();
  }, [instance]);

  useEffect(() => {
    async function fetchBalance() {
      try {
        const res = await instance.balanceOf(checkAccount);

        if (res?._isBigNumber) {
          setBalance(res.toNumber());
        }
      } catch (error) {
        console.error("BalanceOf: Reverted", error);
      }
    }

    !readOnly && checkAccount && fetchBalance();
  }, [checkAccount, readOnly, reloader, instance]);

  useEffect(() => {
    function onTransfer(arg1, arg2, arg3) {
      setReloader(reloader + 1);
    }

    instance.on("Transfer", onTransfer);

    return () => {
      instance.off("Transfer", onTransfer);
    };
  }, [reloader, instance]);

  return {
    balance,
    totalSupply,
    available,
    locked
  };
}
