import React, { useContext, useState, useEffect, useCallback } from "react";
import { BlockchainContext } from "../BlockchainContext";
import MultiTransaction from "../functionals/MultiTransaction";
import { defaultAbiCoder } from "ethers/lib/utils";
import useTokenReader from "../hooks/web3/useTokenReader";
import BTVAssetAbi from "../../contracts/abi/BTVAsset.json";
import { Contract, utils } from "ethers";
import Icon from "../functionals/Icon";

export default function Liquidity({ type, erc20Address }) {
  const { liquiditorContract, readOnly, provider, nexiumContract, account } =
    useContext(BlockchainContext);
  const { balance } = useTokenReader(erc20Address);
  const [validInput, setValidInput] = useState(undefined);
  const [amount, setAmount] = useState(1);
  const [price, setPrice] = useState(0.1);
  const [nxcPrice, setNxcPrice] = useState(0);
  const instance = new Contract(
    erc20Address,
    BTVAssetAbi.abi,
    !readOnly ? provider.getSigner() : provider
  );

  function onAmountInputChange(event) {
    setAmount(parseInt(event.target.value));
  }

  function onPriceInputChange(event) {
    const value = event.target.value === "" ? "0" : event.target.value;
    setPrice(parseFloat(value));
  }

  function onNxcPriceInputChange(event) {
    const value = event.target.value === "" ? "0" : event.target.value;
    setNxcPrice(parseFloat(value));
  }

  useEffect(() => {
    if (!balance) return;

    setValidInput(amount <= balance && amount > 0);
  }, [amount, balance]);

  return (
    <div>
      List token for a price in Eth
      <br />
      <MultiTransaction
        isValid={validInput}
        transactions={[
          {
            name: "Add liquidity",
            contract: instance,
            functionName: "approveAndCall",
            args: [
              liquiditorContract?.address,
              amount,
              defaultAbiCoder.encode(
                ["uint256", "string"],
                [0, "feed"]
              ),
            ]
          }
        ]}
      >
        amount
        <input
          placeholder="amount"
          defaultValue={amount.toString()}
          onChange={onAmountInputChange}
          style={{ width: "48px" }}
        />
      </MultiTransaction>
      <MultiTransaction
        isValid={validInput}
        transactions={[
          {
            name: "List For Eth",
            contract: liquiditorContract,
            functionName: "listWithEth",
            args: [
              instance.address,
              utils.parseEther(price.toString())
            ]
          }
        ]}
      >
        price
        <input
          placeholder="price"
          defaultValue={price.toString()}
          onChange={onPriceInputChange}
          style={{ width: "48px" }}
        />
      </MultiTransaction>
      <MultiTransaction
        isValid={validInput}
        transactions={[
          {
            name: "List For Token (NxC)",
            contract: liquiditorContract,
            functionName: "listWithToken",
            args: [
              instance.address,
              nexiumContract.address,
              nxcPrice
            ]
          }
        ]}
      >
        price
        <input
          placeholder="price"
          defaultValue={nxcPrice.toString()}
          onChange={onNxcPriceInputChange}
          style={{ width: "48px" }}
        />
      </MultiTransaction>
    </div>
  );
}
