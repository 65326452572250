import React from 'react'
import Divider from './Divider';
import Icon from './Icon';

export default function Title({ children }) {
    return (
      <Divider>
        <Icon material="keyboard_arrow_down"/>
        {children}
        <Icon material="keyboard_arrow_down"/>
      </Divider>
    );
  }