import React from "react";
import { Rinkeby } from "../../contracts/networks";
import { useContext, useState, useEffect } from "react";
import { BlockchainContext } from "../BlockchainContext";
import Icon from "./Icon";
import styles from "./Address.module.css"

function format(address) {
  const length = address?.length;

  return (
    address ? address.substring(0, 7) + "..." + address.substring(length - 5, length) :""
  );
}

export default function Address({ hash }) {
  const { chainId } = useContext(BlockchainContext);
  const [copied, setCopied] = useState(false);

  function onCopy() {
    navigator.clipboard.writeText(hash);
    setCopied(true);
  }

  useEffect(() => {
      if (copied) {
      setTimeout(() => {
      setCopied(false);
      }, 1.5 * 1000);
    }
  }, [copied]);

  return (
    <>
      {format(hash)}
      <span
        className={styles.copy}
        style={{ cursor: "pointer" }}
        onClick={onCopy}
      >
        {" "}
        {!copied ? (
          <Icon material="content_copy" />
        ) : (
          <>
          <Icon material="check_circle" />
          {" "}Copied
          </>
        )}
      </span>
    </>
  );
}
