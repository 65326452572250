import React from "react";
import { useContext } from "react";
import { Mainnet, Rinkeby } from "../../contracts/networks";
import { BlockchainContext } from "../BlockchainContext";

export default function Explorer({ hash }) {
  const { chainId } = useContext(BlockchainContext);
  const etherscanLink =
    "https://" +
    (chainId === Rinkeby.chainId ? "rinkeby." : "") +
    "etherscan.io/token/";
  const ethplorerLink = "https://ethplorer.io/address/";

  return (
    <>
      <a href={etherscanLink + hash} target="_blank">
        Etherscan
      </a>
      <>
        {" / "}
        <a href={ethplorerLink + hash} target="_blank">
          Ethplorer
        </a>
      </>
    </>
  );
}
