import React from 'react'
import Divider from './functionals/Divider'

export default function Footer() {
    return (
        <footer>
            <Divider/>
            B2Expand © All rights Reserved.
        </footer>
    )
}