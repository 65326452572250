import React from "react";
import { useContext } from "react";
import useLiquidatedAsset from "../hooks/web3/useLiquidatedAsset";
import { BlockchainContext } from "../BlockchainContext";

export default function Listing({ erc20Address, type }) {
  const { liquiditorContract, nexiumContract } = useContext(BlockchainContext);
  const { available, ethPrice, nxcPrice } = useLiquidatedAsset(erc20Address);

  return (
    <div>
      <h3>{available && available > 0 && "AVAILABLE "}</h3>
      <br />
      eth: {ethPrice ?? "..."}
      <br />
      nxc: {nxcPrice ?? "..."}
    </div>
  );
}
