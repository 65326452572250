import { Rinkeby } from "../../../contracts/networks";
import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BlockchainContext } from "../../BlockchainContext";
import styles from "./NFT.module.css";
import Transaction from "../../functionals/Transaction";
import Icon from "../../functionals/Icon";
import Divider from "../../functionals/Divider";

export default function NFT({
  image,
  rarity,
  name,
  tokenId,
  attributes: [{ value: creation }, { value: id }],
  address,
  asset
}) {
  const { chainId, encapsulatorContract } = useContext(BlockchainContext);
  let navigate = useNavigate();

  function onOpenSeaButton() {
    window.open(
      "https://testnets.opensea.io/assets/" + address + "/" + tokenId,
      "_blank"
    );
  }

  function onExplorerButton() {
    window.open(
      "https://" +
        (chainId === Rinkeby.chainId ? "rinkeby." : "") +
        "etherscan.io/token/" +
        address,
      "_blank"
    );
  }

  function onAssetButton() {
    navigate("/asset/" + asset);
  }

  return (
    <div className={styles.card}>
      <div className={styles.preview}>
      </div>
      <div className="panel" style={{ justifyContent: "center" }}>
        <div className={styles.images}>
          <div className={styles.imagePlaceholder}>
            <img alt={""} src={image} />
          </div>
        </div>
        <h3 onClick={onAssetButton} style={{ marginBottom: "2px", cursor: "pointer" }}>
          <a>
          {name} 
          </a>
          {" n°"}{id}
        </h3>
        <Divider />
        <a className={styles.tokenId} onClick={onExplorerButton}>token #{tokenId}</a>
        <br />
        <br />
          <button onClick={onOpenSeaButton}>
            OpenSea <Icon material="open_in_new" />
          </button>
        <br />
        <Transaction
          name="Swap"
          contract={encapsulatorContract}
          functionName="swapForToken"
          args={[tokenId]}
        >
          Swap for ERC-20
        </Transaction>
      </div>
    </div>
  );
}
