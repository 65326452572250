import { useEffect, useState, useContext } from "react";
import { BlockchainContext } from "../../BlockchainContext";
import useEncapsulator from "./useEncapsulator";

export default function useEncapsulatedAsset(wallet, assetAddress) {
  const { assets } = useContext(BlockchainContext);
  const { ownedUris, supply } = useEncapsulator(wallet);
  const [assetUris, setAssetUris] = useState([]);
  const [assetBalance, setAssetBalance] = useState(undefined);

  useEffect(() => {
    function filterNFTs() {
      const asset = assets.find((i) => i.address === assetAddress);

      if (assetAddress === undefined)
        return ownedUris;
      if (asset)
        return ownedUris.filter(([idx, uri]) => uri.name === asset.name);
      console.error(
        "Unable to find any assets for contract address",
        assetAddress
      );
      return [];
    }

    if (!ownedUris) return;

    const filtered = filterNFTs();
    setAssetUris(filtered);
    setAssetBalance(filtered.length);
  }, [ownedUris, assetAddress, assets]);

  return {
    balance: assetBalance,
    ownedUris: assetUris,
    supply
  };
}
