import React from "react";
import {useParams } from "react-router-dom";
import NFToken from "../library/NFToken";
import {utils} from "ethers";
import { useState } from "react";
import { useEffect } from "react";
import Error from "./fallback/Error";
import Loading from "./fallback/Loading";
import Token from "../library/Token";
import { useContext } from "react";
import { BlockchainContext } from "../BlockchainContext";
import Title from "../functionals/Title";
import Page from "./Page";
import Address from "../functionals/Address";
import { Rinkeby } from "../../contracts/networks";

export default function Collection() {
    const {address} = useParams();
    const [isAddressValid, setIsAddressValid] = useState(undefined);
    const {setReadAccount, chainId} = useContext(BlockchainContext);

    useEffect(() => {
        try {
            utils.getAddress(address);

            setIsAddressValid(true);
            setReadAccount(address);
        } catch (error) {
            setIsAddressValid(false);
        }
    }, [address])

    useEffect(() => {
        return () => {
            setReadAccount(undefined)
        }
    }, [])

    if (isAddressValid === undefined) return (<Loading>Loading...</Loading>)
    if (isAddressValid === false) return (<Error>Could not resolve address</Error>);

    return (
        <Page>
            <h1>Library of <Address hash={address}/></h1>
            <Title>ERC-20 Tokens</Title>
            <Token wallet={address}/>
            <Title>ERC-721 NFTs</Title>
            {chainId === Rinkeby.chainId ? <NFToken wallet={address}/> : <Error>Contract unavailable on Mainnet</Error>}
        </Page>
    )
}