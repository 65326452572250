import React from "react";
import { useContext } from "react";
import { BlockchainContext } from "../BlockchainContext";
import useBalanceList from "./useOwnedAssets";

const defaultCategories = [
  ["rank", "Ranks"],
  ["avatar", "Avatars"],
  ["skin", "Skins"],
  ["event", "Events"],
  ["dev", "Dev Assets"]
];

export default function useTokenAssets(wallet, categories = defaultCategories) {
  const { assets, chainId } = useContext(BlockchainContext);
  const {balances} = useBalanceList(wallet, assets.map(({address}) => address));
  const categorizedAssets = assets.filter(({ category }) => {
    return !!defaultCategories.find(([c]) => c === category);
  });

  if (!wallet) return { assets: categorizedAssets };
  else if (!balances) return {};
  return {
    assets: categorizedAssets.filter(({address}) => balances.find(([a, balance]) => a === address && balance > 0))
  }
}
