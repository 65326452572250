import "./App.css";
import { BlockchainContext } from "./components/BlockchainContext";
import { Rinkeby, Mainnet } from "./contracts/networks";
import { contracts } from "./contracts/encapsulator";
import { assets as mainAssets } from "./assets/erc20Assets-Mainnet";
import { assets as testAssets } from "./assets/erc20Assets-Rinkeby";
import { Contract, utils, providers, BigNumber } from "ethers";
import { useEffect, useState } from "react";
import Main from "./components/Main";
import useProvider from "./components/hooks/web3/useProvider";

function createContract(chainId, provider) {
  return new Contract(
    contracts.get(chainId).address,
    new utils.Interface(contracts.get(chainId).abi.abi),
    provider
  );
}

export default function App() {
  const [readAccount, setReadAccount] = useState(undefined);
  const { chainId, provider, account, readOnly } = useProvider();

  const assets = chainId === Rinkeby.chainId ? testAssets : mainAssets;
  const encapsulatorContract =
    chainId &&
    provider &&
    new Map([
      [
        Mainnet.chainId,
        createContract(
          Mainnet.chainId,
          !readOnly ? provider.getSigner() : provider
        )
      ],
      [
        Rinkeby.chainId,
        createContract(
          Rinkeby.chainId,
          !readOnly ? provider.getSigner() : provider
        )
      ]
    ]);
  const liquiditorContract =
    chainId &&
    provider &&
    new Map([
      [
        Mainnet.chainId,
        new Contract(
          contracts.get(chainId).liquiditor.address,
          new utils.Interface(contracts.get(chainId).liquiditor.abi.abi),
          !readOnly ? provider.getSigner() : provider
        )
      ],
      [
        Rinkeby.chainId,
        new Contract(
          contracts.get(chainId).liquiditor.address,
          new utils.Interface(contracts.get(chainId).liquiditor.abi.abi),
          !readOnly ? provider.getSigner() : provider
        )
      ]
    ]);
  const nexiumContract =
    chainId &&
    provider &&
    new Contract(
      contracts.get(chainId).nexium.address,
      new utils.Interface(contracts.get(chainId).nexium.abi.abi),
      !readOnly ? provider.getSigner() : provider
    );

  return (
    <div className="App">
      <BlockchainContext.Provider
        value={{
          account,
          readAccount,
          setReadAccount,
          readOnly: readOnly,
          provider: provider,
          chainId,
          mainContract: encapsulatorContract,
          testContract: encapsulatorContract,
          encapsulatorContract: encapsulatorContract?.get(chainId),
          liquiditorContract: liquiditorContract?.get(chainId),
          nexiumContract: nexiumContract,
          assets
        }}
      >
        {chainId && <Main />}
      </BlockchainContext.Provider>
    </div>
  );
}
