// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Buy_availability__OEEiF {\n    display: inline-flex;\n    justify-content: space-between;\n    flex-wrap: nowrap;\n    width: 100%;\n    align-items: baseline;\n}\n\n.Buy_availability__OEEiF span h3 {\n    color: var(--color-background-border-1);\n    text-shadow: none;\n}", "",{"version":3,"sources":["webpack://./src/components/modals/Buy.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,8BAA8B;IAC9B,iBAAiB;IACjB,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,uCAAuC;IACvC,iBAAiB;AACrB","sourcesContent":[".availability {\n    display: inline-flex;\n    justify-content: space-between;\n    flex-wrap: nowrap;\n    width: 100%;\n    align-items: baseline;\n}\n\n.availability span h3 {\n    color: var(--color-background-border-1);\n    text-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"availability": "Buy_availability__OEEiF"
};
export default ___CSS_LOADER_EXPORT___;
