import React from "react";
import { useContext } from "react";
import { BlockchainContext } from "../../BlockchainContext";
import useEncapsulator from "../../hooks/web3/useEncapsulator";
import Address from "../../functionals/Address";

export default function NFTokenInfo() {
  const {account} = useContext(BlockchainContext)
  const { balance, supply, address } = useEncapsulator(account);

  return (
    <>
      <i className="cfu-wallet" /> balance:{" "}
      {balance !== undefined ? balance : "..."}
      <br />
      <i className="cfu-tokens" /> supply: {supply !== undefined ? supply : "..."}
      <br />
      <i className="cfu-info" /> <Address hash={address} />
    </>
  );
}
