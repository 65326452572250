import { BlockchainContext } from "../BlockchainContext";
import { useContext, useEffect, useState } from "react";
import { Contract } from "ethers";
import balanceAbi from "../../contracts/abi/IBalance.json";

export default function useBalanceList(wallet, addresses) {
  const { provider, chainId} = useContext(BlockchainContext);
  const [balanceList, setBalanceList] = useState();

  useEffect(() => {
    async function fetchBalances() {
      const balances = await Promise.all(
        addresses.map(async (address) => {
          const contract = new Contract(address, balanceAbi.abi, provider);

          try {
            const balance = await contract.balanceOf(wallet);
            return [address, balance?._isBigNumber ? balance.toNumber() : 0];
          } catch {
            console.warn("BalanceOf", address, ": Error fetching balance.");
          }
        })
      );

      setBalanceList(balances);
    }

    wallet && fetchBalances();
  }, [wallet, provider, chainId]);

  return {
    balances: balanceList
  };
}
