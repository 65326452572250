// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading_loading__bL8m8 span {\n  font-size: 65px;\n}\n\n.Loading_loading__bL8m8 {\n  width: 100%;\n  height: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  color: var(--color-background-border);\n}\n\n@keyframes Loading_rotation__FCO4K {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(359deg);\n  }\n}\n\n.Loading_rotate__DPBky {\n  display: flex;\n  animation-duration: 1s;\n  animation: Loading_rotation__FCO4K 1s infinite linear;\n}\n", "",{"version":3,"sources":["webpack://./src/components/pages/fallback/Loading.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,qCAAqC;AACvC;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qDAAsC;AACxC","sourcesContent":[".loading span {\n  font-size: 65px;\n}\n\n.loading {\n  width: 100%;\n  height: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  color: var(--color-background-border);\n}\n\n@keyframes rotation {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(359deg);\n  }\n}\n\n.rotate {\n  display: flex;\n  animation-duration: 1s;\n  animation: rotation 1s infinite linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "Loading_loading__bL8m8",
	"rotate": "Loading_rotate__DPBky",
	"rotation": "Loading_rotation__FCO4K"
};
export default ___CSS_LOADER_EXPORT___;
