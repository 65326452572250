import React, { useContext, useEffect } from "react";
import styles from "./Token.module.css";
import { BlockchainContext } from "../BlockchainContext";
import Card from "./element/Card";
import { useNavigate } from "react-router-dom";
import HouseTitle from "./element/HouseTitle";
import useTokenAssets from "../hooks/useTokenAssets";
import Error from "../pages/fallback/Error";
import { useLocation } from "react-router-dom";


export default function Token({ wallet }) {
  const { chainId } = useContext(BlockchainContext);
  const { assets } = useTokenAssets(wallet);
  let navigate = useNavigate();
  const location = useLocation();

  const displayedCategories = [
    ["rank", "Ranks"],
    ["avatar", "Avatars"],
    ["skin", "Skins"],
    ["event", "Events"],
    ["dev", "Dev assets"]
  ];

  function getSubCategorizedAssets(filteredAssets) {
    const subcatergoies = filteredAssets
      .map(({ subCategory }) => subCategory)
      .filter((value, index, self) => self.indexOf(value) === index);
    const assetList = [];

    assetList.push([
      undefined,
      filteredAssets.filter(({ subCategory }) => !subCategory)
    ]);

    subcatergoies.map((item) => {
      if (!item) return;
      assetList.push([
        item,
        filteredAssets.filter(({ subCategory }) => subCategory === item)
      ]);
    });

    return assetList;
  }

  //Switches to anchored link
  useEffect(() => {
    const title = document.getElementById(location.hash.substring(1));
    
    location && title && title.scrollIntoView();

    const anchors = document.getElementsByClassName("anchor");

    window.scroll(function(e) {
      var scrollTop = window.scrollTop();
      anchors.each(function(el,i) {
          if (el.offset().top > scrollTop && anchors[i + 1].offset().top < scrollTop) {
               location.hash = this.id;
          }
      });
  });
  }, [assets]);

  if (chainId === undefined) return <div>Loading...</div>;
  if (assets && assets.length === 0)
    return <Error>No ERC-20 found on this wallet</Error>;

  return (
    <div>
      <div className={styles.token_container}>
        {assets &&
          displayedCategories.map(([tag, title], index) => {
            const filteredAssets = assets.filter(
              ({ category }) => category === tag
            );
            const categorizedAssets = getSubCategorizedAssets(filteredAssets);

            if (!filteredAssets.length) return <div key={index}></div>;

            return (
              <React.Fragment key={index}>
                <h1 className="anchor" id={title.toLowerCase()}>{title}</h1>
                {categorizedAssets.map(([sc, scAssets], idx) => {
                  let firstElement = true;

                  return (
                    <React.Fragment key={idx}>
                      {sc && (
                        <div className={styles.subtitle}>
                          <HouseTitle house={sc} />
                        </div>
                      )}
                      {scAssets.map(
                        (
                          {
                            category,
                            img,
                            name,
                            address,
                            subCategory,
                            creation
                          },
                          idx
                        ) => {
                          if (idx > 0) firstElement = false;

                          return (
                            <div className={styles.cardContainer} key={idx}>
                              <Card
                                key={idx}
                                img={"/assets/256/" + img + ".png"}
                                colorScheme={subCategory}
                                name={name}
                                address={address}
                                creation={creation}
                                onClick={() => {
                                  navigate(`/asset/${address}`);
                                }}
                                wallet={wallet}
                              />
                            </div>
                          );
                        }
                      )}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
}
