import React from "react";
import styles from "./Transaction.module.css";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { BlockchainContext } from "../BlockchainContext";
import Icon from '../functionals/Icon'

Transaction.defaultProps = {
  args: []
};

export default function Transaction({
  contract,
  functionName,
  children,
  args,
  onSucess,
  ...props
}) {
  const { account } = useContext(BlockchainContext);
  const statusIcons = {
    Pending: "indeterminate_check_box",
    PendingSignature: "indeterminate_check_box",
    Mining: "refresh",
    Exception: "report_off",
    Success: "done"
  };
  const [state, setState] = useState("");
  const [success, setSuccess] = useState(false);

  async function onTransact() {
    if (!contract || args.filter((e) => e === undefined).length >= 1) {
      console.error("Contract unavailable");
      return;
    }
    console.log(
      "📍 Transaction Initiated",
      "\nby: " + account,
      "\nto: " + contract?.address,
      "\nfn: " + functionName + "(" + args + ")"
    );

    try {
      setState(statusIcons["PendingSignature"]);
      const txn = await contract[functionName](...args);

      setState(statusIcons["Mining"]);
      await txn.wait();
      setState(statusIcons["Success"]);
      setSuccess(true);
      console.log("✅", functionName + "(" + args + ")");
      onSucess && onSucess();
    } catch (error) {
      setState(statusIcons["Exception"]);
      console.log(error.message);
    }
    // await send(...args);
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 5 * 1000);
    }
  }, [success]);

  return (
    <>
      <div className={styles.transaction}>
        <button {...props} onClick={onTransact}>
          {children}
        </button>
        <div>
          <Icon
            id={state === statusIcons["Mining"] ? "spinner" : ""}
            material={state === statusIcons["Success"] && !success ? "" : state}
          />
        </div>
      </div>
      {/* {state.errorMessage} */}
    </>
  );
}
