import React, { useContext } from "react";
import styles from "../Token.module.css";
import useTokenReader from "../../hooks/web3/useTokenReader";
import { BlockchainContext } from "../../BlockchainContext";
import Address from "../../functionals/Address";
import Explorer from "../../functionals/Explorer";
import { useMemo } from "react";
import Icon from "../../functionals/Icon";
import useAssetFromFactory from "../../hooks/web3/useAssetFromFactory";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function Card({
  img,
  name,
  children,
  creation,
  colorScheme,
  onClick,
  address,
  wallet
}) {
  const { readOnly } = useContext(BlockchainContext);
  const { balance, totalSupply, available, locked } = useTokenReader(address, wallet);
  const {asset} = useAssetFromFactory(address);
  const date = useMemo(() => {
    if (!asset) return 
    const timestamp = creation ?? asset.date
    let objDate = new Date(timestamp * 1000);
    return objDate.toGMTString().substring(5, 25 - 8);
  }, [asset]);

  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width < 600, [width]);

  return (
    <div className={[colorScheme, styles.token].join(" ")}>
      <div
        onClick={onClick}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          cursor: onClick ? "pointer" : "inherit"
        }}
      >
        <div className={styles.imagePlaceholder}>
          <img alt={name} src={img} />
        </div>
        <h3>{name}</h3>
      </div>
      <div className={styles.secondary}>
        {!readOnly && (
          <>
           <Icon crypto="wallet" /> balance:{" "}
            <b>{balance !== undefined ? balance : "..."}</b>
            <br />
          </>
        )}
        <Icon crypto="time" /> {date}
        <br />
        <Icon material="account_balance"/>
        {" "}
        {!isMobile && " available: "}
        {available ?? "..."} / {totalSupply ?? "..."}
        <br />
        <i className="cfu-lock" />{" "}
        {locked !== undefined ? (
          locked ? (
            <>
              locked{" "}
              <Icon material="check" id={styles.locked}/>
            </>
          ) : (
            <>
            unlocked{" "}
            <Icon material="close" id={styles.unlocked}/>
            </>
          )
        ) : (
          "..."
        )}
      </div>
    </div>
  );
}
