import React, { useContext, useEffect, useState } from "react";
import { useMemo } from "react";
import Address from "../../functionals/Address";
import { BlockchainContext } from "../../BlockchainContext";
import useTokenReader from "../../hooks/web3/useTokenReader";
import Explorer from "../../functionals/Explorer";
import useAssetFromFactory from "../../hooks/web3/useAssetFromFactory";

export default function TokenInfo({
  address,
  name,
  img,
  category,
  type,
  isFound,
  ...props
}) {
  const { readOnly } = useContext(BlockchainContext);
  const { balance, totalSupply, available, locked } = useTokenReader(address);
  const [collected, setCollected] = useState(false);
  const {asset} = useAssetFromFactory(address);
  const date = useMemo(() => {
    if (!asset) return 
    let objDate = new Date(asset.date * 1000);
    return objDate.toGMTString().substring(5, 25);
  }, [asset]);

  useEffect(() => {
    setCollected(balance && balance > 0);
  }, [balance]);

  return (
    <div {...props}>
      {!readOnly && (
        <>
          <i className="cfu-wallet" /> balance:{" "}
          <b>{balance !== undefined ? balance : "..."}</b>
          <br />
        </>
      )}
      <i className="cfu-exchange-token-3" /> available:{" "}
      {available !== undefined ? available : "..."}
      <br />
      <i className="cfu-tokens" /> supply:{" "}
      {totalSupply !== undefined ? totalSupply : "..."}
      <br />
      <i className="cfu-lock" />{" "}
      {totalSupply !== undefined ? (locked ? "locked" : "unlocked") : "..."}
      <br />
      <i className="cfu-time" /> {date}
      <br />
      <i className="cfu-info" /> <Address hash={address} />
      <br />
      <i className="cfu-block-explorer" /> <Explorer hash={address} />
      <br />
    </div>
  );
}
