import React, { useContext } from "react";
import Token from "../library/Token";
import NFToken from "../library/NFToken";
import { BlockchainContext } from "../BlockchainContext";
import { Rinkeby } from "../../contracts/networks";
import styles from "./Home.module.css";
import Title from "../functionals/Title";
import Page from "./Page";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const { chainId, readOnly, account } =
    useContext(BlockchainContext);
  let navigate = useNavigate();

    function onLearnMoreButton() {
      navigate("/history");
    }

  return (
    <Page>
      <div className={styles.landingContent}>
        <h1>BTV Museum</h1>
        <h3>Beyond The Void Card Swaper</h3>
        <p>
          Beyond The Void is one of the first blockchain games released in 2018.
          <br/>
          <br/>
          Genre: hybrid space MOBA/RTS released on Steam.
          <br/>
          <br/>
          At the genesis of the project, cards, erc-20 tokens, were distributed as rewards. And some were sold on the Beyond the Void marketplace. 
          <br/>
          <br/>
          BTV ERC20 holders are now welcome to convert them into NFT standard, ERC721.
          <br/>
          <br/>
          <button onClick={onLearnMoreButton}>Learn more</button>
        </p>
      </div>
      <Title>ERC-20 Orginal Tokens</Title>
      <Token/>
      {chainId === Rinkeby.chainId && !readOnly && (
        <>
          <Title>ERC-721 NFTs</Title>
          <NFToken wallet={account} />
        </>
      )}
    </Page>
  );
}
