import React from "react";
import Icon from "../../functionals/Icon";
import styles from "./Loading.module.css";

export default function Loading({ children }) {
  return (
    <div className={styles.loading}>
      <div className={styles.rotate}>
        <Icon material="motion_photos_on"/>
      </div>
    </div>
  );
}
