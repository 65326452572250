import React from "react";
import { useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { BlockchainContext } from "../BlockchainContext";
import useAsset from "../hooks/useAsset";
import TokenInfo from "./asset/TokenInfo";
import styles from "./Asset.module.css";
import NFToken from "../library/NFToken";
import Divider from "../functionals/Divider";
import Mint from "../modals/Mint";
import { Mainnet } from "../../contracts/networks";
import Error from "./fallback/Error";
import Page from "./Page";
import Buy from "../modals/Buy";
import { Route, Routes } from "react-router-dom";
import Liquidity from "../modals/Liquidity";
import Icon from "../functionals/Icon";
import Listing from "../modals/Listing";

export default function Asset() {
  const { readOnly, chainId, account } = useContext(BlockchainContext);
  let { address } = useParams();
  let [searchParams] = useSearchParams();
  const asset = useAsset(address);
  const unmintable = readOnly;
  const formattedDescription =
    asset.description && asset?.description.split("\n");

  function UnmintableLock({ children }) {
    return (
      <div className={styles.action + (unmintable ? " " + styles.grayed : "")}>
        {unmintable && (
          <section className={styles.noWallet}>
            <Icon material="error_outline" />
            {readOnly
              ? " Connect your wallet to mint"
              : " Contract not available"}
          </section>
        )}
        {children}
      </div>
    );
  }

  function onHomeButton() {
    
  }

  if (!asset.isFound) return <Error>Asset not found</Error>;

  return (
    <Page>
      <div className={styles.navBar}>
        <button>home</button>
      </div>
      <div className={styles.layout + " " + asset.subcategory}>
        <div className={styles.loreLayout}>
          <img
            alt={asset.name + "-icon"}
            src={"/assets/1024/" + asset.img + ".png"}
          />
          <section>
            <h1>{asset.name}</h1>
            <h3>LORE</h3>
            <p>
              {formattedDescription && formattedDescription.map((line, idx) => {
                return (
                  <React.Fragment key={idx}>
                    {idx > 0 && <br />}
                    {line}
                  </React.Fragment>
                );
              })}
            </p>
          </section>
        </div>
        <div className={styles.infoLayout}>
          <div className={styles.history}>
            <section>
              <h3>HISTORY</h3>
              <p>{asset.history ?? "To be completed..."}</p>
              <h3>CREATION</h3>
              <p>{new Date(asset.creation * 1000).toDateString()}</p>
            </section>
            <section>
              <h3>ERC-20 CONTRACT</h3>
              <TokenInfo {...asset} address={address} />
            </section>
          </div>
          {/* <UnmintableLock>
            <section>
              <Listing type={asset.type} erc20Address={address} />
            </section>
          </UnmintableLock> */}
          <UnmintableLock>
            <section>
              <h3>MINT</h3>
              <Mint type={asset.type} erc20Address={address} />
            </section>
          </UnmintableLock>
          <UnmintableLock>
            <section>
              <Buy type={asset.type} erc20Address={address} />
            </section>
          </UnmintableLock>
          {searchParams.get('admin') === "" && <div className={styles.action}>
            <section>
              <h3>ADD LIQUIDITY</h3>
              <Liquidity type={asset.type} erc20Address={address} />
            </section>
          </div>}
        </div>
      </div>
      <Divider>Encapsulated in</Divider>
      {!unmintable && <NFToken wallet={account} assetAddress={address} />}
    </Page>
  );
}
