import React, { useContext, useState, useEffect } from "react";
import useTokenReader from "../hooks/web3/useTokenReader";
import MultiTransaction from "../functionals/MultiTransaction";
import { BlockchainContext } from "../BlockchainContext";
import { Contract } from "ethers";
import BTVAssetAbi from '../../contracts/abi/BTVAsset.json'
import { defaultAbiCoder } from "ethers/lib/utils";

export default function Mint({ erc20Address, type }) {
  const { encapsulatorContract, provider, readOnly } =
    useContext(BlockchainContext);
  const { balance } = useTokenReader(erc20Address);
  const instance = new Contract(
    erc20Address,
    BTVAssetAbi.abi,
    !readOnly ? provider.getSigner() : provider
  );
  const [validInput, setValidInput] = useState(undefined);
  const [amount, setAmount] = useState(1);

  function onInputChange(event) {
    setAmount(parseInt(event.target.value));
  }

  useEffect(() => {
    if (!balance) return;

    setValidInput(amount <= balance && amount > 0);
  }, [amount, balance]);

  return (
    <div>
      You can swap your {balance !== undefined ? balance : "..."} tokens for
      NFTs
      <br />
      <MultiTransaction
        isValid={validInput}
        transactions={[
          {
            name: "Swap ERC-20 for NFT",
            contract: instance,
            functionName: "approveAndCall",
            args: [encapsulatorContract?.address, amount, defaultAbiCoder.encode(["uint256"], [type])]
          }
        ]}
      >
        <input
          placeholder="amount"
          defaultValue={amount.toString()}
          onChange={onInputChange}
          style={{ width: "48px" }}
        />
      </MultiTransaction>
    </div>
  );
}
