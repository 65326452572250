import { utils } from "ethers";
import { useContext, useEffect, useState } from "react";
import { BlockchainContext } from "../../BlockchainContext";
import useTokenReader from "./useTokenReader";

export default function useLiquidatedAsset(assetAddress) {
  const { liquiditorContract, nexiumContract } = useContext(BlockchainContext);
  const { balance: available } = useTokenReader(
    assetAddress,
    liquiditorContract?.address
  );
  const [ethPrice, setEthPrice] = useState(undefined);
  const [nxcPrice, setNxcPrice] = useState(undefined);

  useEffect(() => {
    async function fetchPrice() {
      try {
        const res = await liquiditorContract.ethPrice(assetAddress);

        if (res?._isBigNumber) {
          setEthPrice(utils.formatEther(res));
        }
      } catch (error) {
        console.error("Price: Reverted", error);
      }
    }

    async function fetchNxcPrice() {
      try {
        const res = await liquiditorContract.tokenPrice(assetAddress, nexiumContract.address);

        if (res?._isBigNumber) {
          setNxcPrice(res.toNumber());
        }
      } catch (error) {
        console.error("Price: Reverted", error);
      }
    }

    liquiditorContract && fetchPrice();
    liquiditorContract && fetchNxcPrice();
  }, [available, liquiditorContract, assetAddress]);

  return {
    ethPrice,
    nxcPrice,
    available
  };
}
