import React, { useContext, useState, useEffect } from "react";
import useTokenReader from "../hooks/web3/useTokenReader";
import MultiTransaction from "../functionals/MultiTransaction";
import { BlockchainContext } from "../BlockchainContext";
import { utils } from "ethers";
import { defaultAbiCoder } from "ethers/lib/utils";
import useLiquidatedAsset from "../hooks/web3/useLiquidatedAsset";
import Icon from "../functionals/Icon";
import styles from "./Buy.module.css";

export default function Buy({ erc20Address, type }) {
  const { liquiditorContract, nexiumContract } = useContext(BlockchainContext);
  const { balance } = useTokenReader(erc20Address);
  const { available, ethPrice, nxcPrice } = useLiquidatedAsset(erc20Address);
  const { balance: nexiumBalance } = useTokenReader(nexiumContract.address);
  const [validInput, setValidInput] = useState(undefined);
  const [amount, setAmount] = useState(1);

  function onInputChange(event) {
    setAmount(parseInt(event.target.value));
  }

  useEffect(() => {
    if (!balance) return;

    setValidInput(amount <= balance && amount > 0);
  }, [amount, balance]);

  return (
    <div>
      <div className={styles.availability}>
        <h3>
          {available !== undefined ? (
            available > 0 ? (
              <>
                {"FOR SALE "}
                <Icon material="done" />
              </>
            ) : (
              <>
                {"NOT FOR SALE "}
                <Icon material="close" />
              </>
            )
          ) : (
            "..."
          )}
        </h3>
        <span>
          <h3>{available !== undefined ? available : "..."} tokens left</h3>
        </span>
      </div>
      <br />
      {available !== undefined && available > 0 &&
        <>
          <MultiTransaction
            isValid={validInput && ethPrice}
            transactions={[
              {
                name: "Mint for " + (ethPrice ?? "...") + " ETH",
                contract: liquiditorContract,
                functionName: "buyWithEth",
                args: [
                  type,
                  amount,
                  {
                    value: utils.parseEther(
                      (amount * (ethPrice ?? 0)).toString()
                    ),
                    gasLimit: 1000000
                  }
                ]
              }
            ]}
          >
            <input
              placeholder="amount"
              defaultValue={amount.toString()}
              onChange={onInputChange}
              style={{ width: "48px" }}
            />
          </MultiTransaction>
          <MultiTransaction
            isValid={validInput && nxcPrice}
            transactions={[
              {
                name: "Mint for a " + (nxcPrice ?? "...") + " NxC burn",
                contract: nexiumContract,
                functionName: "approveAndCall",
                args: [
                  liquiditorContract?.address,
                  amount * nxcPrice,
                  defaultAbiCoder.encode(["uint256", "string"], [type, "buy"]),
                  { gasLimit: 1000000 }
                ]
              }
            ]}
          >
            <input
              placeholder="amount"
              defaultValue={amount.toString()}
              onChange={onInputChange}
              style={{ width: "48px" }}
            />
          </MultiTransaction>
        </>
      }
    </div>
  );
}
