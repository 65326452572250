import assetLibraryAbi from "../../../contracts/abi/AssetLibrary.json";
import { useState, useContext, useEffect } from "react";
import { BlockchainContext } from "../../BlockchainContext";
import { Contract } from "ethers";

export default function useAssetFromFactory(address) {
  const { provider } = useContext(BlockchainContext);
  const [assetLibrary] = useState(
    new Contract(
      "0xe4439c2eb86e2a8c0e28e91003e06739c2674dd8",
      assetLibraryAbi.abi,
      provider
    )
  );
  const [asset, setAsset] = useState();

  useEffect(() => {
    async function fetchAsset() {
        const assets = await assetLibrary.getAllAssets();
        const found = assets.find(({assetAddress: a}) => a.toLowerCase() === address.toLowerCase());

        setAsset({
            date: found.registerDate,
            origin: found.origin,
            createdSupply: found.createdSupply,
            type: found.type
        })
    }

    address && assetLibrary && fetchAsset();
  }, [address])

  return {
    asset
  };
}
