import React from "react";
import styles from "./MultiTransaction.module.css";
import Transaction from "./Transaction";
import { useState } from "react";
import Icon from "./Icon";

export default function MultiTransaction({ transactions, children, isValid }) {
  const [txnIndex, setTxnIndex] = useState(0);

  function displayTransaction() {
    if (txnIndex >= transactions.length) {
        setTxnIndex(0);
        return;
    }
    const { name, ...txn } = transactions[txnIndex];

    return <Transaction disabled={!isValid}onSucess={() => {setTxnIndex(txnIndex + 1)}} {...txn}>{name}</Transaction>;
  }

  return (
    <div className={styles.multiTransaction}>
      <div className={styles.layout}>
        {transactions?.length > 1 && <div className={styles.state}>{txnIndex + 1}/{transactions.length}</div>}
        {transactions && displayTransaction(txnIndex)}
      </div>
      {isValid !== undefined && !isValid && <Icon material="dangerous"/>}
      {children}
    </div>
  );
}
