import React from "react";
import styles from "./Divider.module.css";

export default function Divider({ children }) {
  return (
    <div className={styles.layout}>
      <div className={styles.divider}></div>
      {children && <div className={styles.content} >{children}</div>}
      <div className={styles.divider}></div>
    </div>
  );
}
