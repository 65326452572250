import React from "react";
import { useContext, useState } from "react";
import { BlockchainContext } from "../../BlockchainContext";
import assetLibraryAbi from "../../../contracts/abi/AssetLibrary.json";
import { BigNumber, Contract } from "ethers";
import { useEffect } from "react";
import Address from "../../functionals/Address";

export default function AssetLibrary() {
  const { provider } = useContext(BlockchainContext);
  const [assetLibrary] = useState(
    new Contract(
      "0xe4439c2eb86e2a8c0e28e91003e06739c2674dd8",
      assetLibraryAbi.abi,
      provider
    )
  );
  console.log(assetLibrary);
  const [allAssets, setAllAssets] = useState([]);

  useEffect(() => {
    async function getAllAssets() {
      setAllAssets(await assetLibrary.getAllAssets());
      console.log(allAssets);
    }

    getAllAssets();
  }, [assetLibrary]);

  useEffect(() => {
    console.log(allAssets);
  }, [allAssets])

  return (
    <>
      <h2>
        Asset Library :{" "}
        <Address hash={"0xe4439c2eb86e2a8c0e28e91003e06739c2674dd8"} />
      </h2>
      <h3>getAllAssets:</h3>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Address</th>
            <th>Burn Address</th>
            <th>Origin</th>
            <th>Lock</th>
            <th>Code</th>
            <th>Name</th>
            <th>Symbol</th>
            <th>Decimal</th>
            <th>Created Supply</th>
            <th>Total Supply</th>
            <th>Identifier</th>
            <th>AssetType</th>
            <th>Version</th>
            <th>Register Date</th>
          </tr>
        </thead>
        <tbody>
          {allAssets.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index}</td>
                <td>
                  <Address hash={item[0]} />
                </td>
                <td>
                  <Address hash={item[7]} />
                </td>
                <td>
                  <Address hash={item[12]} />
                </td>
                <td>{item[8] ? "true" : "false"}</td>
                {item.map((e, idx) => {
                  if (
                    [0, 7, 8, 12].find((i) => i === idx) !== undefined ||
                    idx >= 14
                  )
                    return undefined;
                  if (idx === 13) {
                    return (
                      <td key={idx}>
                        {e?._isBigNumber
                          ? new Date(
                              BigNumber.from(e).toNumber() * 1000
                            ).toDateString()
                          : e}
                      </td>
                    );
                  }
                  return (
                    <td key={idx}>
                      {e?._isBigNumber ? BigNumber.from(e).toNumber() : e}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
