import React from "react";
import Page from "./Page";
import AssetLibrary from "./dev/AssetLibrary";

export default function Dev() {

  return (
    <Page>
      <h1>Development Resources</h1>
      <AssetLibrary/>
    </Page>
  );
}
