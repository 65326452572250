import React from "react";
import { useState } from "react";
import lore from "../../../assets/lore.json";
import Icon from "../../functionals/Icon";
import styles from "./HouseTitle.module.css";

export default function HouseTitle({ house }) {
  const houseLore = lore.houses.find((e) => e.name === house);
  const [expanded, setExpanded] = useState(false);
  const formattedDescription = houseLore?.description.split("\n");

  const displayedSubcategories = new Map([
    ["trexar", "Trexar House"],
    ["vunhuma", "Vunhuma House"],
    ["xoletsa", "Xoletsa House"],
    ["visgalt", "Visgalt House"],
    ["hignos", "Hignos House"]
  ]);

  function onLearnMoreButton() {
    setExpanded(!expanded);
  }

  if (!houseLore) return <>{"No description found."}</>;
  return (
    <div className={[styles.container, house].join(" ")}>
      <div className={styles.background} />
      <div className={styles.titleContainer}>
        <h1 onClick={onLearnMoreButton} className={styles.title}>
          {displayedSubcategories.get(house)}
          <img alt={houseLore.name} src={houseLore.img} />
          <div id={expanded ? styles.down : styles.up}>
            <Icon material="arrow_drop_up"/>
          </div>
        </h1>
      </div>
      <div className={expanded ? styles.expanded : styles.collapsed}>
        {formattedDescription.map((line, index) => {
          return (
            <React.Fragment key={index}>
              {index > 0 && <br />}
              {line}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
