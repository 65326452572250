import React from "react";
import Page from "./Page";

export default function Technical() {
  return (
    <Page>
      <h1>Technical</h1>
      <h2>What does it mean to swap?</h2>
      <p>
        Fast forward in 2022, the NFT space is filled with many new players. In
        the meantime, it seems the space has seen many new types of jobs such as
        real estate agents for metaverse, marketplaces for land rentals, and of
        course NFT archeologists who are after the artefacts with an historical
        value. Spells of Genesis was the first trading card game created on
        Counterparty (Bitcoin layer) in 2015, way before NFTs even existed.
        Their community was looking to revive the cards by wrapping them for
        minting as ERC721 on Ethereum. It seems the demand doesn’t stop as seen
        during the crypto Summer 2021.
      </p>
      <p>
        Although the Beyond The Void has stopped the development, its community
        joined by blockchain archeologists have requested for many months that
        we facilitate the swap from ERC20 to ERC721.
      </p>
      <p>
        We have been working on the inventory and thought of the best way to
        allow BTV cards holders to wrap them in ERC721. We have decided to
        launch a website complete with the inventory and ERC20 holders can
        easily make the swap. B2Expand will not take fees or intervene on what
        the NFT holders will do with them.
      </p>
      <h2>Why not burn the ERC20 when swapping to ERC721 ?</h2>
      <p>
        We ruled out the burning of the corresponding ERC20 when swapped to
        ERC721. We feel it’s important to keep the ERC20 creation date. Also, in
        the long-term, if there are new technologies or standards in the future,
        it makes sense to have kept the orginal contract we could go back to and
        retrieve the information from.
      </p>
      <h2>What to expect from the Beyond the Void Museum</h2>
      <p>
        At the launch of the website taking place in June: BTV card holders can
        <ul>
          <li>Connect the wallet</li>
          <li>Select the card from the inventory</li>
          <li>Click swap</li>
          <li>And you’ll be holding a ERC721!</li>
        </ul>
      </p>
      <p>
        It’s simple and fast, we put a lot of attention for an easy User
        Experience.
      </p>
      <p>
        For real-time information and connect with other community members,
        please follow us on Twitter and enter a chat room on Discord.
      </p>
    </Page>
  );
}
