import { Mainnet, Rinkeby } from "./networks";
import EncapsulatorAbi from "./abi/BTVEncapsulator.json";
import LiquiditorAbi from './abi/BTVLiquidity.json'
import NexiumAbi from './abi/Nexium.json'

const mainId = Mainnet.chainId;
const testId = Rinkeby.chainId;

export const contracts = new Map([
  [
    mainId,
    {
      address: "0xa739c6B01091229fe2d2fCAE7DeE95159BA95457",
      abi: EncapsulatorAbi,
      liquiditor: {address: "0xB368Be167D48C00fd321Aec8E6AF9cA9322AaBEc", abi: LiquiditorAbi},
      nexium: {address: "0x45e42D659D9f9466cD5DF622506033145a9b89Bc", abi: NexiumAbi},
    }
  ],
  [
    31337,
    {
      address: "0xC9aF5Fd65a3EbbF40cE979404784c828d43bf3Ab",
      abi: EncapsulatorAbi,
      liquiditor: {address: "0x99ca41044161aD5e131fEd80DfCc764a366b8162", abi: LiquiditorAbi},
      nexium: {address: "0x45e42D659D9f9466cD5DF622506033145a9b89Bc", abi: NexiumAbi},
    }
  ],
  [
    testId,
    {
      address: "0xfA7AED0Dcc0bE75762BdFDD61AE6B02A75f71B85",
      abi: EncapsulatorAbi,
      liquiditor: {address: "0x6C95eB00132b6f45948bD50B5F16aCfc31b23521", abi: LiquiditorAbi},
      nexium: {address: "0x6012Ce8732d94198adE52F30b9A597aEF7E9b7F2", abi: NexiumAbi},
    }
  ]
]);
