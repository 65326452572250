import React from "react";
import Icon from "../../functionals/Icon";
import styles from "./Error.module.css";

export default function Error({children}) {
  return (
    <div className={styles.error}>
      <Icon material="search_off"/>
      {children}
    </div>
  );
}
