import {
    Mainnet,
    Rinkeby,
  } from "../../../contracts/networks";

export default function useNetwork(chainId) {
    const supportedNetworks = [Mainnet, Rinkeby];
    const current = supportedNetworks.find(network => network.chainId === chainId);

    function name() {
        return (!current ? "Unsupported" : current.name);
    }

    return (
        {name: name()}
    )
}