// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Transaction_transaction__FsYJb {\n  justify-content: space-between;\n  gap: var(--space-small);\n  display: flex;\n  align-items: center;\n}\n\n.Transaction_transaction__FsYJb button {\n  width: 100%;\n  margin-top: 2px;\n  margin-bottom: 2px;\n}\n\n.Transaction_transaction__FsYJb .Transaction_status__neHQ7 {\n  width: 30px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/functionals/Transaction.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".transaction {\n  justify-content: space-between;\n  gap: var(--space-small);\n  display: flex;\n  align-items: center;\n}\n\n.transaction button {\n  width: 100%;\n  margin-top: 2px;\n  margin-bottom: 2px;\n}\n\n.transaction .status {\n  width: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transaction": "Transaction_transaction__FsYJb",
	"status": "Transaction_status__neHQ7"
};
export default ___CSS_LOADER_EXPORT___;
