import React, { useContext } from "react";
import { BlockchainContext } from "../BlockchainContext";
import styles from "./Token.module.css";
import NFT from "./element/NFT";
import useEncapsulatedAsset from "../hooks/web3/useEncapsulatedAsset";
import NFTokenInfo from "./element/NFTokenInfo";

export default function NFToken({ wallet, assetAddress }) {
  const { encapsulatorContract, assets } =
    useContext(BlockchainContext);
  const {ownedUris} = useEncapsulatedAsset(
    wallet,
    assetAddress
  );

  return (
    <div>
      <div className={styles.flex}>
        <section className="panel">
          <h3>ERC-721 CONTRACT</h3>
          <NFTokenInfo />
        </section>
      </div>
      <br />
      <div className={styles.token_container}>
        {ownedUris.map(([id, uri], index) => {
          const asset = assets.find((e) => e.name === uri.name);

          return (
            <NFT
              key={id}
              {...uri}
              asset={asset.address}
              rarity={asset.rarity}
              tokenId={id}
              address={encapsulatorContract.address}
            />
          );
        })}
      </div>
    </div>
  );
}
