import React from "react";
import Page from "./Page";

export default function History() {
  return (
    <Page>
      <h1>History</h1>
      <h2>Reviving Beyond the Void cards from 2017.</h2>
      Back in 2017, B2Expand was one of the first blockchain native video game
      studios. With it’s first title Beyond the Void, a space-themed
      real-time-strategy game, B2Expand created a marketplace for players to
      buy, sell, trade their game assets. Back then very few tools were
      available, Opensea was just starting developing the platform. Maybe it’s
      hard for newcomers to realise how confidential all this was.
    </Page>
  );
}
