import React from "react";
import Header from "./Header";
import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Collection from "./pages/Collection";
import Asset from "./pages/Asset";
import Dev from "./pages/Dev";
import History from "./pages/History";
import Technical from "./pages/Technical";

export default function Main() {
  return (
    <>
      <BrowserRouter>
        <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/address/:address" element={<Collection />} />
            <Route path="/asset/:address/*" element={<Asset />} />
            <Route path="/history" element={<History />} />
            <Route path="/technical" element={<Technical />} />
            <Route path="/dev" element={<Dev />} />
          </Routes>
      </BrowserRouter>
    </>
  );
}
