// ERC-20 Token assets, file would also be used to create NFT types on contract deployment.
// {
//     address: string, // Address of the ERC-20 Token contract
//     type: number, // TypeId, index of createType() call
//     name: string,
//     description: string,
//     category: string,
//     img: "skin1" // Image path is composed of /{category}/{img}.png
// }

export const assets = [
    {
        address: "0x11f89045e8F36D471E5Bd527156068AEE4074b3D",
        type: 0,
        name: "Golden Excalibur",
        description: "This is a development asset. Type 0",
        category: "dev",
        img: "dev/dev-excalibur",
        rarity: "common",
        price: 10000000,
    },
    {
        address: "0xCf5BebAD5b998340f26334236Ddb8B4EE4E03098",
        type: 1,
        name: "Junkalibur",
        description: "This is a development asset. Type 1",
        category: "dev",
        img: "dev/dev-junkalibur",
        rarity: "epic",
        price: 10000000 * 2,
    },
    {
        address: "0x0e3AC8d9AeccCDd70257c34A16E09eb08e9A283a",
        type: 2,
        name: "Molten Core Fusion",
        description: "This is a development asset. Type 2",
        category: "dev",
        img: "dev/dev-molten",
        rarity: "rare",
        price: 10000000 * 3,
    }
]