// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_landingContent__-BXfe {\n    min-height: 40vh;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    max-width: 70%;\n    display: flex;\n    flex-direction: column;\n    margin: auto;\n}\n\n.Home_landingContent__-BXfe h1 {\n    font-size: 3em;\n    margin-bottom: 0px;\n}", "",{"version":3,"sources":["webpack://./src/components/pages/Home.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".landingContent {\n    min-height: 40vh;\n    text-align: center;\n    justify-content: center;\n    align-items: center;\n    max-width: 70%;\n    display: flex;\n    flex-direction: column;\n    margin: auto;\n}\n\n.landingContent h1 {\n    font-size: 3em;\n    margin-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landingContent": "Home_landingContent__-BXfe"
};
export default ___CSS_LOADER_EXPORT___;
