import { assets as testAssets } from "../../assets/erc20Assets-Rinkeby";
import { assets as mainAssets } from "../../assets/erc20Assets-Mainnet";
import { Rinkeby } from "../../contracts/networks";
import { useContext } from "react";
import { BlockchainContext } from "../BlockchainContext";

export default function useAsset(address) {
  const { chainId } = useContext(BlockchainContext);
  const assets = chainId === Rinkeby.chainId ? testAssets : mainAssets;
  const asset = assets.find(({ address: a }) => a === address);
  const {
    type,
    name,
    description,
    category,
    img,
    subCategory,
    history,
    creation,
    price
  } = asset ?? {};

  return {
    type,
    name,
    description,
    category,
    img,
    history,
    creation,
    price,
    subcategory: subCategory,
    isFound: asset !== undefined
  };
}
