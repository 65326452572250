import { useEffect, useState, useContext } from "react";
import { BlockchainContext } from "../../BlockchainContext";
import { Buffer } from "buffer";
import { Rinkeby } from "../../../contracts/networks";

export default function useEncapsulator(wallet) {
  const { encapsulatorContract, readOnly, chainId } =
    useContext(BlockchainContext);
  const [ownedIds, setOwnedIds] = useState([]);
  const [ownedUris, setOwnedUris] = useState([]);
  const [balance, setBalance] = useState(undefined);
  const [supply, setSupply] = useState(undefined);

  const [reloader, setReloader] = useState(false);
  const instance =
    chainId === Rinkeby.chainId ? encapsulatorContract : encapsulatorContract;

  useEffect(() => {
    async function fetchBalance() {
      try {
        const res = await instance.balanceOf(wallet);

        if (res?._isBigNumber) {
          setBalance(res.toNumber());
        }
      } catch (error) {
        console.error("BalanceOf: Reverted", error);
      }
    }

    async function fetchTotalSupply() {
      try {
        const res = await instance.totalSupply();

        if (res?._isBigNumber) {
          setSupply(res.toNumber());
        }
      } catch (error) {
        console.error("TotalSupply: Reverted", error);
      }
    }

    instance && fetchTotalSupply();
    instance && wallet && fetchBalance();
  }, [wallet, readOnly, reloader, instance]);

  useEffect(() => {
    async function fetchOwnedIds(range) {
      const ids = await Promise.all(
        [...Array(range)].map(async (_, index) => {
          let tokenId = await instance.tokenOfOwnerByIndex(wallet, index);

          return tokenId.toNumber();
        })
      );
      setOwnedIds(ids);
      return ids;
    }

    async function fetchUris(ids) {
      setOwnedUris(
        await Promise.all(
          ids.map(async (id) => {
            const uri = await instance.tokenURI(id);
            const prefix = "data:application/json;base64,";
            console.log("URI:", uri)
            const content = uri.substring(prefix.length, uri.length);
            const decoded = Buffer.from(content, "base64").toString();

            return [id, JSON.parse(decoded)];
          })
        )
      );
    }

    async function fetchOwnedNFTs() {
      if (supply !== undefined) {
        if (!wallet) await fetchUris([...Array(supply)].map((_, idx) => idx));
        else if (balance !== undefined) {
          await fetchUris(await fetchOwnedIds(balance));
        }
      }
    }

    fetchOwnedNFTs();
  }, [balance, supply, instance, wallet]);



  useEffect(() => {
    const onTokenUpdate = (from) => {
      if (from === wallet) {
        setReloader(r => !r);
      }
    }

    if (!instance) return;

    instance.on("tokenMinted", onTokenUpdate);
    instance.on("tokenUnminted", onTokenUpdate);

    return () => {
      instance.off("tokenMinted", onTokenUpdate);
      instance.off("tokenUnminted", onTokenUpdate);
    };
  }, [instance, wallet]);

  return {
    balance,
    supply,
    address: instance?.address,
    ownedIds,
    ownedUris
  };
}
