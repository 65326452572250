import React, { useContext, useMemo } from "react";
import { Mainnet } from "../contracts/networks";
import useNetwork from "./hooks/web3/useNetwork";
import { BlockchainContext } from "./BlockchainContext";
import useWalletRequest from "./hooks/web3/useWalletRequest";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
// import WalletSearch from "./functionals/WalletSeach";
import useWindowDimensions from "./hooks/useWindowDimensions";

function format(address) {
  const length = address.length;

  return (
    address.substring(0, 5) + "..." + address.substring(length - 5, length)
  );
}

export default function Header() {
  const { account, chainId } = useContext(BlockchainContext);
  const { requestAccounts } = useWalletRequest();
  const { name: networkName } = useNetwork(chainId);
  const { switchChain } = useWalletRequest();
  let navigate = useNavigate();
  let location = useLocation();
  const { width } = useWindowDimensions();
  const title = useMemo(() => {
    return width < 600 ? "BTV" : "Beyond the Void Museum";
  }, [width]);
  const wallet = useMemo(() => {
    return width < 600 ? "Connect" : "Connect Wallet";
  }, [width]);

  function onConnectButton() {
    requestAccounts();
  }

  function onNetworkButton() {
    if (chainId && chainId !== Mainnet.chainId) {
      switchChain(
        "0x" + Mainnet.chainId,
        Mainnet.chainName,
        "https://mainnet.infura.io/v3/8494cc12431a4873b49a1ec89327393e"
      );
    }
  }

  function onWalletButton() {
    navigate("/address/" + account);
  }

  function onTitleButton() {
    console.log("location", location);
    navigate("/");
  }

  return (
    <header className={styles.header}>
      {width > 600 && (
        <div className={styles.title} onClick={onTitleButton}>
          {title}
        </div>
      )}
      <div>
        <button
          className={location.pathname === "/" ? styles.selected : ""}
          onClick={onTitleButton}
        >
          Home
        </button>
        <button
          className={location.pathname === "/history" ? styles.selected : ""}
          onClick={() => navigate("/history")}
        >
          History
        </button>
        <button
          className={location.pathname === "/technical" ? styles.selected : ""}
          onClick={() => navigate("/technical")}
        >
          Technical
        </button>
      </div>
      {/* <WalletSearch /> */}
      <div>
        {width > 600 && (
          <div style={{ fontSize: "smaller" }} onClick={onNetworkButton}>
            {networkName}
          </div>
        )}
        {account && <button onClick={onWalletButton}>{format(account)}</button>}
        {!account && <button onClick={onConnectButton}>{wallet}</button>}
      </div>
    </header>
  );
}
